import BoardController from "modules/BoardController";
import arxs from "infra/arxs";

class ProtectionEquipmentBoard extends BoardController {
  enrichCard(card, stateProxy) {
    return {
      ...card,

      sortId: card.sort && card.sort.id,
      sort: this.getCodeElementValue(
        card.sort,
        stateProxy.getter("codeElementsById")
      ),
      kindId: card.kind && card.kind.id,
      kind: this.getCodeElementValue(
        card.kind,
        stateProxy.getter("codeElementsById")
      ),
      typeId: card.type && card.type.id,
      type: this.getCodeElementValue(
        card.type,
        stateProxy.getter("codeElementsById")
      ),
      title: this.getCodeElementValue(
        card.type,
        stateProxy.getter("codeElementsById")
      ),
      legalStructureId: card.legalStructure && card.legalStructure.id,
      legalStructure: this.getLookupValue(
        stateProxy.getter("legalStructureMap"),
        card.legalStructure
      ),
      branchId: card.branch && card.branch.id,
      branch: this.getLookupValue(stateProxy.getter("branchMap"), card.branch),
      building: this.getLookupValue(
        stateProxy.getter("buildingMap"),
        card.building
      ),
      location: this.getLookupValue(
        stateProxy.getter("locationMap"),
        card.location
      ),
      supplier: this.getLookupValue(
        stateProxy.getter("supplierMap"),
        card.supplier
      ),
      owner: this.getLookupValue(stateProxy.getter("employeeMap"), card.owner),
      energySupplies: (card.energySupplies || []).map((x) =>
        this.getCodeElementValue(x, stateProxy.getter("codeElementsById"))
      ),
      extraSpecifications: (card.extraSpecifications || []).map(x => x.description),
      recommendation: this.getLookupValueWithFieldNames(
        stateProxy.getter("recommendationMap"),
        card.recommendation, ["uniqueNumber", "title"]
      ),
      purchaseDate: card.purchaseDate && new Date(card.purchaseDate),
      productionDateTime:
        card.productionDateTime && new Date(card.productionDateTime),
      depreciationPeriodEnd:
        card.depreciationPeriodEnd && new Date(card.depreciationPeriodEnd),
      expirationDate: card.expirationDate && new Date(card.expirationDate),
      createdAt: card.createdAt && new Date(card.createdAt),
      combinedInstallationUniqueNumber: this.getUniqueNumbersOfCombinedInstallations(stateProxy, card.inboundLinks),
      periodical_uniqueNumber: this.getUniqueNumbersOfPeriodicals(stateProxy, card.inboundLinks),
    };
  }

  getCardSearchTerms(card) {
    return [
      card.description,
      card.uniqueNumber,
      card.internalNumber,
      card.sort,
      card.kind,
      card.type,
      card.legalStructure,
      card.branch,
      card.building,
      card.location,
      (card.geoLocation || {}).street,
      (card.geoLocation || {}).city,
      card.brand,
      card.model,
      card.serialNumber,
      ...(card.tags || []).map((x) => x.name),
      ...(card.extraSpecifications || []),
      card.combinedInstallationUniqueNumber,
      card.periodical_uniqueNumber,
    ];
  }

  async loadData(requestedLookups, stateProxy) {
    return arxs.Api.lookups.subscribe(requestedLookups, (lookups) => {
      if (lookups.protectionEquipments) {
        lookups.pristine = lookups.protectionEquipments
          .orderByDescending((card) => card.createdAt)
          .map((card) => {
            return {
              ...card,
              activities: card.numberOfMessages,
              selected: () => !!stateProxy.getter("selected")[card.id],
            };
          });
        delete lookups.protectionEquipments;
      }
      stateProxy.setter(lookups, () => Promise.resolve({ lookups }));
    });
  }

  onDrop = (droppedStatus, item) => {
    arxs.logger("No drag & drop supported for Labourmeans yet");
  };
}
export default new ProtectionEquipmentBoard();
