import { ArXsState } from "infra/arxs";
import ModuleMetadata, { BoardViewType } from "modules/ModuleMetadata";
import MultiYearPlanWizard from "./MultiYearPlanWizard";
import MultiYearPlanActions from "./MultiYearPlanActions";
import MultiYearPlanBoard from "./MultiYearPlanBoard";
import {
  ObjectDocumentType,
  OriginModuleEnum,
  RelationshipType,
  MultiYearPlanStatus,
} from "infra/api/contracts";
import MultiYearPlanCard from "./MultiYearPlanCard";

const getMultiYearPlanMetadata = (arxs: ArXsState): ModuleMetadata => {
  const module = OriginModuleEnum.GlobalPreventionPlan;

  return {
    module,
    title: arxs.t("modules.multiyearplan"),
    icon: "far fa-globe",
    path: "/multiyearplan",
    base: {
      name: "MultiYearPlan",
      route: "/multiyearplan",
      getResource: () => arxs.Api.getResource("multiyearplan"),
      subscribe: (id, module, handler) =>
        arxs.Api.lookups.subscribeToId(module, id, handler),
      readAction: "GlobalPreventionPlan.Read",
      writeAction: "GlobalPreventionPlan.Write",
    },
    settings: {
      name: "MultiYearPlanSettings",
      route: "/multiyearplan/settings",
      getResource: () => arxs.ApiClient.safety.multiYearPlanSettings,
      readAction: "GlobalPreventionPlan.Settings.Write",
      writeAction: "GlobalPreventionPlan.Settings.Write",
    },
    detailspane: [
      {
        initiallyOpen: true,
        fields: [
          { name: "title", fullWidth: true },
          { name: "internalReference", fullWidth: true },
          { name: "riskAnalysisValue" },
          { name: "priority" },
          { name: "description", fullWidth: true },
          {
            name: "goals",
            fullWidth: true,
            props: { markDown: true },
          },
          {
            name: "workingMethod",
            fullWidth: true,
            props: { markDown: true },
          },
          { name: "subject" },
          { name: "origins" },
          { name: "sort" },
          { name: "kind", parent: "sort" },
          { name: "type", parent: "kind" },
          { name: "start" },
          { name: "end" },
          { name: "period" },
          {
            name: "estimatedDuration",
            unit: arxs.t("unit.hour").toLowerCase(),
          },
          { name: "estimatedCost", unit: arxs.t("unit.euro").toLowerCase() },
          // { name: "geoLocation" },
          {
            name: "createdAt",
            props: { longDate: true },
            title: arxs.t("field.createdAt"),
            fullWidth: true,
          },
          {
            name: "relationships",
          },
        ],
      },
      {
        title: arxs.t("details_pane.location"),
        initiallyOpen: true,
        fields: [
          // { name: "legalStructure" },
          // { name: "branch", parent: "legalStructure" },
          // { name: "building", parent: "branch" },
          // { name: "location", parent: "building" },
          { name: "geoLocation" },
        ],
      },
      {
        title: arxs.t("details_pane.tags"),
        initiallyOpen: true,
        fields: [{ name: "tags" }],
      },
    ],
    allowedDocumentTypes: [
      ObjectDocumentType.Image,
      ObjectDocumentType.AdditionalDocument,
    ],
    statuses: [
      MultiYearPlanStatus.ToPlan,
      MultiYearPlanStatus.Planned,
      MultiYearPlanStatus.Overdue,
      MultiYearPlanStatus.Completed,
      MultiYearPlanStatus.Closed,
    ],
    actions: MultiYearPlanActions.getActions(
      OriginModuleEnum.GlobalPreventionPlan,
      false
    ),
    wizard: {
      wizardClass: MultiYearPlanWizard,
      steps: [
        {
          title: arxs.t("wizard.common.identification"),
          fields: [
            [
              {
                name: "title",
              },
              {
                name: "internalReference",
                title: arxs.t("wizard.field.internal_reference"),
              },
            ],
            [
              {
                name: "description",
                title: arxs.t("wizard.multi_year_plan.description"),
              },
            ],
            [
              {
                name: "subject",
                type: "cardlist",
                props: {
                  modules: [
                    OriginModuleEnum.SchoolGroup,
                    OriginModuleEnum.School,
                    OriginModuleEnum.Building,
                    OriginModuleEnum.Room,
                    OriginModuleEnum.Labourmeans,
                    OriginModuleEnum.Pbm,
                    OriginModuleEnum.EquipmentInstallation,
                    OriginModuleEnum.HazardousSubstance,
                    OriginModuleEnum.CombinedInstallation,
                    OriginModuleEnum.IntangibleAsset,
                  ],
                  overridePrefilter: true,
                  disableInitialValue: true,
                },
                onChange: (stateProxy) => {
                  MultiYearPlanWizard.setScopeFields(
                    stateProxy,
                    "subject"
                  ).then(() =>
                    MultiYearPlanWizard.applyProcessFlow(stateProxy, "subject")
                  );
                },
                title: arxs.t("field.subject"),
              },
              { name: "geoLocation" },
            ],
            [
              {
                name: "type",
                parent: "kind",
                grandParent: "sort",
                props: { code: "GlobalPreventionPlan.Domain" },
                type: "codeelementlist",
                title: arxs.t("wizard.multi_year_plan.category"),
                onChange: (stateProxy) =>
                  MultiYearPlanWizard.applyProcessFlow(stateProxy, "subject"),
              },
            ],
            [{ name: "tags", type: "tag" }],
          ],
          getSecurityContext: (writeAction, getValue) => {
            const args = [
              "legalStructure",
              "branch",
              "building",
              "location",
            ].map(getValue);
            return arxs.securityContext.buildForObjectOrUserContext(
              writeAction,
              ...(args as any)
            );
          },
        },
        {
          title: arxs.t("wizard.common.details"),
          fields: [
            [
              {
                name: "goals",
                title: arxs.t("wizard.multi_year_plan.goals"),
                props: { markDown: true },
              },
              {
                name: "workingMethod",
                title: arxs.t("wizard.multi_year_plan.working_method"),
                props: { markDown: true },
              },
            ],
            [
              {
                name: "start",
                props: {
                  disabledOnEdit: (stateProxy: any) => (stateProxy.getField("status") !== MultiYearPlanStatus.ToPlan)
                },
              },
              {
                name: "end",
                props: {
                  disabledOnEdit: (stateProxy: any) => (stateProxy.getField("status") !== MultiYearPlanStatus.ToPlan)
                },
              },
            ],
            [
              {
                name: "estimatedDuration",
              },
              {
                name: "estimatedCost",
              },
            ],
            [
              {
                name: "priority",
              },
              {
                name: "riskAnalysisValue",
                type: "riskValue",
                props: {
                  type: "number",
                },
              },
            ],
          ],
        },
        {
          title: arxs.t("details_pane.involved_people"),
          fields: [
            [
              {
                name: "responsible",
                type: "cardlist",
                props: {
                  modules: [OriginModuleEnum.Employee],
                  condensed: true,
                },
              },
            ],
            [
              {
                name: "relationships",
                type: "relationshiplist",
                props: {
                  types: [RelationshipType.CoResponsible, RelationshipType.Cc],
                },
              },
            ],
          ],
        },
        {
          title: arxs.t("wizard.common.upload"),
          fields: [
            [
              { name: "images", type: "image" },
              { name: "documents", type: "document" },
            ],
          ],
        },
      ],
      addNewState: "ToPlan",
      lookups: {
        multiYearPlans: [],
        codeElementsById: {},
        tagMap: {},
        legalStructureMap: {},
        branchMap: {},
        buildingMap: {},
        locationMap: {},
        labourmeanMap: {},
        protectionEquipmentMap: {},
        hazardousSubstanceMap: {},
        equipmentMap: {},
        intangibleAssetMap: {},
        combinedInstallationMap: {},
        objectsByModule: {},
        employeeMap: {},
        userRoleMap: {},
      },
    },
    board: {
      lookups: {
        multiYearPlans: [],
        codeElementsById: {},
        tagMap: {},
        legalStructureMap: {},
        branchMap: {},
        buildingMap: {},
        locationMap: {},
        labourmeanMap: {},
        protectionEquipmentMap: {},
        hazardousSubstanceMap: {},
        equipmentMap: {},
        intangibleAssetMap: {},
        combinedInstallationMap: {},
        objectsByModule: {},
        employeeMap: {},
        userRoleMap: {},
        inspectionMap: {},
        maintenanceMap: {},
        incidentMap: {},
      },
      gridColums: [
        { name: "status" },
        { name: "priority" },
        { name: "riskValue" },
        { name: "period" },
        { name: "activities" },
        { name: "uniqueNumber" },
        { name: "numberOfImages" },
        { name: "numberOfDocuments" },
        { name: "tags" },
        { name: "legalStructure" },
        { name: "branch" },
        { name: "building" },
        { name: "location" },
        { name: "geoLocation" },
        { name: "sort" },
        { name: "kind" },
        { name: "type" },
        { name: "title" },
        { name: "internalReference" },
        { name: "description" },
        { name: "goals" },
        { name: "workingMethod" },
        { name: "createdAt" },
        { name: "start" },
        { name: "end" },
        { name: "estimatedCost" },
        { name: "estimatedDuration" },
        { name: "responsible" },
        { name: "coResponsibles" },
        { name: "subject_uniqueNumber" },
        { name: "subject_sort" },
        { name: "subject_kind" },
        { name: "subject_type" },
        { name: "subject_internalReference" },
      ],
      boardClass: MultiYearPlanBoard,
      bucketConfig: [
        {
          module: module,
          status: "ToPlan",
          onDrop: (state) =>
            MultiYearPlanActions.dropActions().onDropInToPlan(state),
          requiredActions: ["unplan"],
        },
        {
          module: module,
          status: "Closed",
          onDrop: (state) =>
            MultiYearPlanActions.dropActions().onDropInClosed(state),
          requiredActions: ["close"],
        },
        {
          module: module,
          status: "Planned",
          onDrop: (state) =>
            MultiYearPlanActions.dropActions().onDropInPlanned(state),
          requiredActions: ["plan", "replan"],
        },
        {
          module: module,
          status: "Completed",
          onDrop: (state) =>
            MultiYearPlanActions.dropActions().onDropInCompleted(state),
          requiredActions: ["complete"],
        },
      ],
      views: [
        BoardViewType.KanBan,
        BoardViewType.Grid,
        BoardViewType.Tree,
        BoardViewType.Map,
      ],
    },
    cardClass: MultiYearPlanCard
  };
};
export default getMultiYearPlanMetadata;
