import Api from "./Api";
import ApiClient from "./api/client";
import ReportClient from "./reporting/client";
import Identity from "./Identity";
import ImageProxy from "./image/ImageProxy";
import { formatDate } from "@progress/kendo-intl";
import { ProductType } from "infra/Types";
import loadIcons from "./icons/Icons";
import { FormDefinition, FormValues, OriginModuleEnum, FormValuesItemsBySubject } from "./api/contracts";

import ModuleMetadataRegistry from "modules/ModuleMetadataRegistry";
import EnumMetadata from "infra/EnumMetadata";
import SecurityContextFactory from "infra/SecurityContext";
// @ts-ignore
import seq from "seq-logging/browser";
import loadTranslations from "./TranslationLoader";

export interface UrlParseResult {
  protocol: string;
  host: string;
  hostname: string;
  port: string;
  pathname: string;
  search: string;
  searchObject: string;
  hash: string;
}

export interface formCompletionResult {
  isCompleted: boolean;
  unCompletedItemIds?: Array<string>;
}

export interface Profile {
  allowedActions: { [x: string]: boolean };
}

export interface ArXsState {
  buildVersion: string;
  classicUrl: string;
  generateClassicUrl(path: string): string;

  Api: typeof Api;
  ApiClient: typeof ApiClient;
  ReportClient: typeof ReportClient;
  Identity: typeof Identity;
  ImageProxy: typeof ImageProxy;

  productName: string;
  productType: ProductType;
  language: string;
  onReadyForLogin: Promise<void>;
  onLoggedIn: Promise<void>;

  t(key: string, args?: {} | undefined): string;

  modules: any;
  statuses: any;
  actions: any;
  documentTypes: any;
  iconTypes: any;
  codeElementTypes: any;
  hierarchyTypes: any;
  enums: any;

  getStatusClassName(module: string, status: string): string;

  tryParseInt(value?: string): number | null;
  parseURL(url: string): UrlParseResult;
  parseQuery(search: string): { [key: string]: string };
  isValidUrl(possibleUrl: string): boolean;
  isActionAllowed(requiredAction: string): boolean;
  getFormCompletionResult(
    formDefinition: FormDefinition,
    formValues: FormValues,
    subjectIds: Array<string>
  ): formCompletionResult;
  loadExternalScript(url: string, id: string, callback: any): any;
  dateTime: {
    timeAgo(date?: string | Date): string;
    formatDate(date: string | Date): string;
    formatTime(date: string | Date): string;
    formatLongDate(date: string | Date): string;
    formatDateTime(date: string | Date): string;
    formatDuration(dateFrom?: string | Date, dateTo?: string | Date): string;
    daysBetween(dateFrom: string | Date, dateTo: string | Date): number;
    hoursBetween(dateFrom: string | Date, dateTo: string | Date): number;
    addHours(date: string | Date, hours: number): Date;
    addMinutes(date: string | Date, minutes: number): Date;
    addDays(date: string | Date, days: number): Date;
    dateEquals(dateLeft?: string | Date, dateRight?: string | Date): boolean;
    dateAtMidnight(date: string | Date): Date;
    dateOnDayOfWeek(date: string | Date, day: number): Date;
    dateOnDayOfMonth(date: string | Date, day: number): Date;
  };

  number: {
    formatDecimal(number?: number): string;
  };

  uuid: {
    generate(): string;
    isValid(value: string): boolean;
  }

  generateUniqueId(): string;

  logger: {
    info(...data: any[]): void;
    warn(...data: any[]): void;
    error(...data: any[]): void;
  };
  inspect(arg: any): any;

  moduleMetadataRegistry: ModuleMetadataRegistry;

  getIncrementalIndex(): number;
  getStableId(...tokens: string[]): number;

  assert(assertion: () => boolean, error: string): void;

  // Used by CustomGridColumnMenuCheckboxFilter
  translations: any;

  securityContext: SecurityContextFactory;

  icons: any;
  searchTokens: any;
}

function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

function getProductName(productType: ProductType) {
  switch (productType) {
    case ProductType.Helios:
      return "ARXS HELIOS";
    default:
      return "ARXS HYPERION";
  }
}

const arxs: ArXsState = (() => {
  const sessionId = uuidv4();
  const env = process.env;

  const tryParseInt = (value?: string) => {
    if (!value) return null;
    try {
      return parseInt(value);
    } catch {
      return null;
    }
  };

  const parseURL = (url: string) => {
    if (isValidUrl(url)) {
      const parser = document.createElement("a");
      parser.href = url;
      const searchObject = parseQuery(parser.search);

      return {
        protocol: parser.protocol,
        host: parser.host,
        hostname: parser.hostname,
        port: parser.port,
        pathname: parser.pathname,
        search: parser.search,
        searchObject: searchObject,
        hash: parser.hash,
      };
    }

    return {
      protocol: "",
      host: "",
      hostname: "",
      port: "",
      pathname: "",
      search: "",
      searchObject: {},
      hash: "",
    };
  };

  const parseQuery = (search: string) => {
    // Convert query string to object
    let queries = search.replace(/^\?/, "").split("&");
    let searchObject: any = {};
    for (let i = 0; i < queries.length; i++) {
      let split = queries[i].split("=");
      searchObject[split[0].toLowerCase()] = split[1];
    }
    return searchObject;
  };

  const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
  const uuidHelper = {
    generate: uuidv4,
    isValid: (value: string) => !!(value || "").match(uuidRegex)
  };

  const isValidUrl = (possibleUrl: string) => {
    let url;

    try {
      url = new URL(possibleUrl);
    } catch (_) {
      return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
  };

  const isActionAllowed = (requiredAction: string) => {
    const profile = arxs.Identity.profile as Profile;
    return !requiredAction || profile.allowedActions[requiredAction];
  };

  const getFormCompletionResult = (
    formDefinition: FormDefinition,
    formValues: FormValues,
    subjectIds: Array<string>
  ) => {
    //TODO: we should get this list from the form control registry; this should define if we have the possibility to make something required or not.
    const controlTypesForValidation = ["select","checkbox", "remark", "riskMeasurement", "riskAnalysis", "preRecommendation", "recommendation"];
    const requiredItemsWithoutValue: string[] = [];

    let actualFormDefinition = formDefinition;

    const extractUsedSubjectIds = (itemsBySubject: FormValuesItemsBySubject): string[] => {
      return Object.values(itemsBySubject || {})
        .flatMap(subjectObj => Object.keys(subjectObj || {}));
    };

    if (formDefinition) {
      if (formDefinition.link && formDefinition.link.id){
        const actualForm = arxs.Api.lookups.resolveSubject({
          module: OriginModuleEnum.Form,
          id: formDefinition.link.id,
        });

        actualFormDefinition = actualForm.formDefinition;
      } 

      const controlTypesForValidationInScope = ((actualFormDefinition || {}).controls || []).filter((x) => controlTypesForValidation.includes(x.type as string)).map(x => x.id);

      const requiredItemIds:string[] = ((actualFormDefinition || {}).items || [])
      .filter((x) => x.required && controlTypesForValidationInScope.includes(x.control))
      .map(x => x.id)
      .filter((id): id is string => typeof id === 'string' && id.length > 0);

      const hasFormValues = formValues && formValues.itemsBySubject && Object.keys(formValues.itemsBySubject).length > 0;

      const usedSubjectIds = (formValues && formValues.itemsBySubject && extractUsedSubjectIds(formValues.itemsBySubject)) || [];

      if (!hasFormValues || subjectIds.except(usedSubjectIds).length !== 0) {
        requiredItemsWithoutValue.push(...requiredItemIds);
      } else {
        const allItems = Object.values(formValues.itemsBySubject || {})
          .flatMap(idAndItems => Object.values(idAndItems || {}))
          .flat();
        
        const requiredItemsStatus = requiredItemIds.map(requiredItemId => {
          const matchingItem: any = allItems.find((item : any) => item?.id === requiredItemId);
          if (!matchingItem) return requiredItemId; 
          return matchingItem.value ? null : requiredItemId; 
        });
        
        requiredItemsWithoutValue.push(...requiredItemsStatus.filter((id): id is string => Boolean(id)));
      }

      return {
        isCompleted: requiredItemsWithoutValue.length === 0,
        unCompletedItemIds: requiredItemsWithoutValue,
      };
    }
    return { isCompleted: true };
  };

  const searchTokens = {
    status: "status",
    action: "action",
    subscribed: "subscribed",
    link: "link",
  };

  const loadExternalScript = (url: string, id: string, callback: any): any => {
    const existingScript = document.getElementById(id);
    if (!existingScript) {
      const script = document.createElement("script");
      script.src = url;
      script.id = id;
      script.async = true;
      document.body.appendChild(script);
      script.onload = () => {
        if (callback) callback();
      };
    }
    if (existingScript && callback) callback();
  };

  const enums = new EnumMetadata();

  const iconTypes = {
    ghs: "ghs",
    icon: "icon",
  };

  const documentTypes = {
    titles: {},
    none: "none",
    additionaldocument: "additionaldocument",
    manual: "manual",
    certificate: "certificate",
    maindocument: "maindocument",
    evacuationplan: "evacuationplan",
    floorplan: "floorplan",
    partitioningplan: "partitioningplan",
    sds: "sds",
    chemistrycard: "chemistrycard",
    participantlist: "participantlist",
    syllabus: "syllabus",
    preadvice: "preadvice",
    formaladvice: "formaladvice",
    generaladvice: "generaladvice",
    quote: "quote",
    order: "order",
    riskanalysis: "riskanalysis",
    controle: "controle",
    maintenance: "maintenance",
    procedure: "procedure",
    historicreport: "historicreport",
    declarationform: "declarationform",
    witnessdeclaration: "witnessdeclaration",
    image: "image",
    landregister: "landregister",
    schoolrules: "schoolrules",
    workrules: "workrules",
    incidentinvestigation: "incidentinvestigation",
    safetyinstructioncard: "safetyinstructioncard",
    insurancedocument: "insurancedocument",
    postfiche: "postfiche",
    contract: "contract",
    formimage: "formimage",
    formdocument: "formdocument",
  };

  const hierarchyTypes = {
    flat: "Flat",
    kindAndType: "KindAndType",
    sortAndKindAndType: "SortKindType",
    nested: "Nested",
  };

  const codeElementTypes = {
    root: "Root",
    sort: "Aard",
    kind: "Soort",
    type: "Type",
    item: "Item",
    group: "Group",
    norm: "Norm",
  };

  const modules = {
    keys: {
      PeriodicMaintenance: "PeriodicMaintenance",
      PeriodicControl: "PeriodicControl",
      Task: "Task",
      NotificationDefect: "NotificationDefect",
      ActivityEntry: "ActivityEntry",

      Building: "Building",
      Room: "Room",
      Labourmeans: "Labourmeans",
      PBM: "PBM",
      EquipmentInstallation: "EquipmentInstallation",
      HazardousSubstance: "HazardousSubstance",
      CombinedInstallation: "CombinedInstallation",
      IntangibleAsset: "IntangibleAsset",

      Commissioning: "Commissioning",
      SafetyInstructionCard: "SafetyInstructionCard",
      OutOfCommissioning: "OutOfCommissioning",
      Consultancy: "Consultancy",
      RiskAnalysis: "RiskAnalysis",
      IncidentManagement: "IncidentManagement",
      GlobalPreventionPlan: "GlobalPreventionPlan",
      YearActionPlan: "YearActionPlan",
      Training: "Training",

      Document: "Document",

      Employee: "Employee",
      Supplier: "Supplier",
      Student: "Student",

      SchoolGroup: "SchoolGroup",
      School: "School",
      UserRole: "UserRole",

      Contact: "Contact",

      Project: "Project",
      SupportTicket: "SupportTicket",
      Report: "Report",
      Form: "Form",
      Periodical: "Periodical",
      InstructionCard: "InstructionCard",
    },
    titles: {},
    icons: enums.classNames["OriginModuleEnum"],
    assetTypes: {
      Building: true,
      Room: true,
      Labourmeans: true,
      PBM: true,
      EquipmentInstallation: true,
      HazardousSubstance: true,
    },
    getModuleForUniqueNumber: (uniqueNumber: string) => {
      const prefix = (uniqueNumber.split("-")[0] || "").toUpperCase();
      switch (prefix) {
        case "ABM":
          return "Labourmeans";
        case "BSM":
          return "PBM";
        case "UIN":
          return "EquipmentInstallation";
        case "GSP":
          return "HazardousSubstance";
        case "SIN":
          return "CombinedInstallation";
        case "LOK":
        case "LOC":
          return "Room";
        case "GEB":
          return "Building";
        case "TAB":
          return "Task";
        case "IDS":
          return "Commissioning";
        case "BDS":
          return "OutOfCommissioning";
        case "VIK":
          return "SafetyInstructionCard";
        case "ADV":
          return "Consultancy";
        case "DOB":
          return "Document";
        case "MJP":
        case "GPP":
          return "GlobalPreventionPlan";
        case "JAP":
          return "YearActionPlan";
        case "CON":
          return "PeriodicControl";
        case "OND":
          return "PeriodicMaintenance";
        case "RIB":
          return "RiskAnalysis";
        case "INC":
          return "IncidentManagement";
        case "SCH":
          return "School";
        case "SGS":
          return "SchoolGroup";
        case "MEL":
          return "NotificationDefect";
        case "PRS":
          return "Employee";
        case "BES":
          return "Management";
        case "LLN":
          return "Student";
        case "LEV":
          return "Supplier";
        case "LVK":
          return "Supplier";
        case "OPL":
          return "Training";
        case "ACT":
          return "ActivityEntry";
        case "CNT":
          return "Contact";
        case "PRJ":
          return "Project";
        case "REP":
          return "Report";
        case "IMA":
          return "IntangibleAsset";
        case "FRM":
          return "Form";
        case "PER":
          return "Periodical";
        case "WIN":
          return "InstructionCard";
      }
    },
  };

  const statuses = {
    titles: {},
    classNames: enums.classNames["Status"],
  };

  const actions = {
    titles: {},
    icons: {
      reactivate: "fas fa-redo-alt",
      edit: "far fa-pencil",
      archive: "far fa-archive",
      accept: "fas fa-check-double",
      refuse: "far fa-window-close",
      unrefuse: "far fa-window-close",
      hold: "fas fa-hand-paper",
      unhold: "fa fa-reply",
      complete: "fas fa-check-double",
      complete_form: "far fa-clipboard-check",
      verify_form: "fas fa-user-check",
      amend_actual_duration: "fas fa-clock",
      verify: "fas fa-user-check",
      reschedule: "far fa-calendar-alt",
      sick: "far fa-clinic-medical",
      to_investigate: "far fa-microscope",
      gantt: "fas fa-tasks-alt",
      set_inprocess: "fas fa-redo-alt fa-rotate-180",
      plan: "far fa-clock",
      unplan: "fas fa-redo-alt fa-rotate-180",
      replan: "fas fa-redo-alt fa-rotate-180",
      close: "far fa-do-not-enter",
      transfer_to_myp: "far fa-globe",
      create_follow_up: "far fa-chevron-square-right",
      create_task: "far fa-tasks",
      create_inspection: "far fa-check-square",
      create_maintenance: "far fa-tools",
      create_activity: "fal fa-file-signature",
      create_incident: "far fa-exclamation-triangle",
      create_riskanalysis: "far fa-retweet",
      create_safetyinstructioncard: "far fa-shield-alt",
      create_instructioncard: "far fa-file-alt",
      generate: "far fa-file-alt",
      request: "fas fa-check-double",
      to_treatment: "far fa-microscope",
      to_sign: "fas fa-signature",
      finalize: "fas fa-check-double",
      sign: "fas fa-signature",
      deactivate: "far fa-do-not-enter",
      generate_occurrence: "fas fa-plus"
    },
  };

  const number = {
    formatDecimal: (number: number) => {
      var locale = "nl-BE"; // navigator.languages ? navigator.languages[0]: navigator.language;
      return number.toLocaleString(locale);
    },
  };

  const dateTime = {
    timeAgo: (date?: string | Date) => "",
    formatDate: (date: string | Date) => {
      const dateInstance = typeof date === "string" ? new Date(date) : date;
      return formatDate(dateInstance, "d", service.language);
    },
    formatTime: (date: string | Date) => {
      const dateInstance = typeof date === "string" ? new Date(date) : date;
      return formatDate(dateInstance, "HH:mm", service.language);
    },
    formatLongDate: (date: string | Date) => {
      const dateInstance = typeof date === "string" ? new Date(date) : date;
      return formatDate(dateInstance, "EEEE d MMMM y", service.language);
    },
    formatDateTime: (date: string | Date) => {
      const dateInstance = typeof date === "string" ? new Date(date) : date;
      return (
        formatDate(dateInstance, "d", service.language) +
        " " +
        formatDate(dateInstance, "HH:mm", service.language)
      );
    },
    formatDuration: (dateFrom?: string | Date, dateTo?: string | Date) => "",
    daysBetween: (dateFrom?: string | Date, dateTo?: string | Date) => {
      if (!dateFrom || !dateTo) return 0;
      const dateFromInstance =
        typeof dateFrom === "string" ? new Date(dateFrom) : dateFrom;
      const dateToInstance =
        typeof dateTo === "string" ? new Date(dateTo) : dateTo;
      return Math.round(
        (dateToInstance.getTime() - dateFromInstance.getTime()) /
          (1000 * 3600 * 24)
      );
    },
    hoursBetween: (dateFrom?: string | Date, dateTo?: string | Date) => {
      if (!dateFrom || !dateTo) return 0;
      const dateFromInstance =
        typeof dateFrom === "string" ? new Date(dateFrom) : dateFrom;
      const dateToInstance =
        typeof dateTo === "string" ? new Date(dateTo) : dateTo;
      return (
        Math.round(
          (dateToInstance.getTime() - dateFromInstance.getTime()) / (100 * 3600)
        ) / 10
      );
    },
    addMonths: (date: string | Date, months: number) => {
      var result = new Date(date);
      result.setMonth(result.getMonth() + months);
      return result;
    },
    addDays: (date: string | Date, days: number) => {
      var result = new Date(date);
      result.setTime(result.getTime() + days * 24 * 60 * 60 * 1000);
      return result;
    },
    addHours: (date: string | Date, hours: number) => {
      var result = new Date(date);
      result.setTime(result.getTime() + hours * 60 * 60 * 1000);
      return result;
    },
    addMinutes: (date: string | Date, minutes: number) => {
      var result = new Date(date);
      result.setTime(result.getTime() + minutes * 60 * 1000);
      return result;
    },
    dateEquals: (dateLeft?: string | Date, dateRight?: string | Date) => {
      if (!dateLeft || !dateRight) return false;
      const dateLeftInstance =
        typeof dateLeft === "string" ? new Date(dateLeft) : dateLeft;
      const dateRightInstance =
        typeof dateRight === "string" ? new Date(dateRight) : dateRight;
      return (
        dateLeftInstance.setHours(0, 0, 0, 0) ===
        dateRightInstance.setHours(0, 0, 0, 0)
      );
    },
    dateAtMidnight: (date: string | Date) => {
      const dateInstance = typeof date === "string" ? new Date(date) : date;
      return new Date(
        dateInstance.getFullYear(),
        dateInstance.getMonth(),
        dateInstance.getDate()
      );
    },
    dateOnDayOfWeek: (date: string | Date, day: number) => {
      const dateInstance = typeof date === "string" ? new Date(date) : date;
      return dateTime.addDays(dateInstance, day - dateInstance.getDay());
    },
    dateOnDayOfMonth: (date: string | Date, day: number) => {
      const dateInstance = typeof date === "string" ? new Date(date) : date;
      return dateTime.addDays(dateInstance, day - dateInstance.getDate());
    },
  };

  const getIncrementalIndex = (function () {
    let index = 0;
    return () => index++;
  })();

  const getStableId = (function () {
    const idMap: any = {};
    return (...tokens: string[]) => {
      const id = tokens.filter((x) => x).join("-");

      let value = idMap[id];

      if (!value) {
        value = getIncrementalIndex();
        idMap[id] = value;
      }

      return value;
    };
  })();

  const generateUniqueId = () => `id-${getIncrementalIndex()}`;

  const logger = (() => {
    const logger = new seq.Logger({
      apiKey: env.REACT_APP_SEQ_APIKEY,
      serverUrl: env.REACT_APP_SEQ_ENDPOINT,
      onError: () => {},
    });

    const getKeysFromTemplate = (template: string): string[] => {
      const regex = /\{(\w+)\}/g;
      let match: RegExpExecArray | null;
      const keys: string[] = [];
      while ((match = regex.exec(template)) !== null) {
        keys.push(match[1]);
      }
      return keys;
    };

    const log = (
      level: "Information" | "Warning" | "Error",
      ...data: any[]
    ) => {
      const rawMessage = data[0] || "";
      const isStringMessage = typeof rawMessage === "string";
      const rawMessagePayload = isStringMessage ? {} : { raw: JSON.stringify(rawMessage) };
      const message = isStringMessage ? rawMessage : "Unsupported log message";
      const keys = isStringMessage ? getKeysFromTemplate(rawMessage) : [];
      const args = data.slice(1);
      const payload: any = keys.reduce((acc, key, i) => {
        if (i >= args.length) {
          return acc;
        }
        let arg = args[i];
        if (typeof arg === "object") {
          arg =  arg.toString ? arg.toString() : JSON.stringify(arg);
        }
        return ({ ...acc, [key]: arg });
      }, {});
      if (!keys.includes("stack")) {
        keys.push("stack");
        payload["stack"] = new Error().stack;
      }

      const profile: any = (service.Identity || {}).profile || {};

      logger.emit({
        timestamp: new Date(),
        level: level,
        messageTemplate: "FRONTEND - " + message,
        properties: {
          sessionId,
          userAgent: navigator.userAgent,
          userId: profile.id,
          userName: profile.username,
          email: profile.email,
          User: profile.fullName,
          ...payload,
          ...rawMessagePayload,
        },
      });
      logger.flush();

      // const log =
      //   level === "Warning"
      //     ? console.warn
      //     : level === "Error"
      //     ? console.error
      //     : console.log;
      // log(rawMessage);
    };

    return {
      info: (...data: any[]) => log("Information", ...data),
      warn: (...data: any[]) => log("Warning", ...data),
      error: (...data: any[]) => log("Error", ...data),
    };
  })();

  const inspect = (arg: any) => {
    console.log(arg);
    return arg;
  };

  const assert = (assertion: () => boolean, error: string) => {
    if (!assertion()) {
      arxs.logger.info("Assertion failed: {error}", error);
      throw Error(error);
    }
  };

  const translations = {};

  const securityContext = {} as SecurityContextFactory;

  const icons = {};

  const trimStart = (str: string, start: string) =>
    str.startsWith(start) ? str.substring(start.length) : str;

  const classicUrl =
    env.REACT_APP_CLASSIC_URL && env.REACT_APP_CLASSIC_URL.startsWith("http")
      ? env.REACT_APP_CLASSIC_URL
      : `${window.location.origin}/${env.REACT_APP_CLASSIC_URL || "classic"}`;

  const generateClassicUrl = (path: string) => {
    const classicPath = process.env.REACT_APP_CLASSIC_URL || "classic";

    path = trimStart(path, "/");
    path = trimStart(path, classicPath);
    path = trimStart(path, "/");

    return `${classicUrl}/${path}`;
  };

  const getStatusClassName = (module: string, status: string) => {
    if (module) {
      const meta = arxs.moduleMetadataRegistry.get(module)
      const statusColorOverride = meta.statusColorOverride || [];
  
      const override = statusColorOverride
        .filter((x: any) => x.status === status)
        .map((x: any) => x.color)
        .firstOrDefault();
      if (override) {
        return override;
      }
    }

    return arxs.statuses.classNames[status];
  };

  const service = {
    buildVersion: env.REACT_APP_BUILD_VERSION || env.NODE_ENV,

    classicUrl,
    generateClassicUrl,
    Api,
    ApiClient,
    ReportClient,
    Identity,
    ImageProxy,
    onReadyForLogin: Promise.resolve(),
    onLoggedIn: Promise.resolve(),

    productType: ProductType.Helios,
    getProductName, 
    productName: getProductName(ProductType.Helios),
    language: "nl",
    t: (key: string, arg?: {} | undefined) => key,

    modules,
    statuses,
    actions,
    documentTypes,
    iconTypes,
    enums,
    getStatusClassName,

    tryParseInt,
    parseURL,
    parseQuery,
    isValidUrl,
    isActionAllowed,
    getFormCompletionResult,
    loadExternalScript,
    dateTime,
    number,
    uuid: uuidHelper,
    generateUniqueId,

    logger,
    inspect,
    hierarchyTypes,
    codeElementTypes,

    moduleMetadataRegistry: new ModuleMetadataRegistry(),

    getIncrementalIndex,
    getStableId,
    assert,

    translations,
    securityContext,
    icons,
    searchTokens,
  };

  service.securityContext = new SecurityContextFactory(service);

  const onAuthenticated = new Promise<void>((resolve) => {
    Identity.subscribeToAuthenticationUpdate(() => {
      if (Identity.isAuthenticated()) {
        Api.initialize();
        Api.initializeSignalr();

        resolve();
      }
    });
  });

  service.onReadyForLogin = loadIcons(service)
    .then((icons: any) => {
      service.icons = icons;
    })
    .then(Identity.initialize)
    .then(() => loadTranslations(service));

  service.onLoggedIn = Promise.all([service.onReadyForLogin, onAuthenticated])
    .then(() => loadTranslations(service))
    .then(() => service.moduleMetadataRegistry.initialize(arxs));

  return service;
})();

export default arxs;