import React from "react";
import WizardController from "modules/WizardController";
import arxs from "infra/arxs";
import CardList from "components/controls/cardlist/CardList";
import { OriginModuleEnum, TaskRequestStatus } from "infra/api/contracts";

class TaskRequestWizard extends WizardController {
  onLookupsChange(stateProxy) {
    return new Promise((resolve, reject) => {
      resolve(this.loadRelatedRequests(stateProxy));
    });
  }

  loadRelatedRequests = (stateProxy) => {
    return new Promise((resolve, reject) => {
      const taskRequests = stateProxy.getter("taskRequests");
      const objectsByModule = stateProxy.getter("objectsByModule");
      const data = stateProxy.getter("data");
      const pristine = stateProxy.getter("pristine");

      const getValue = this.buildGetValue(stateProxy);
      const ago = 1000 * 60 * 60 * 24 * 30; // = ca 1 month

      const getRelated = (subject) => {
        let related = (taskRequests || [])
          .filter(
            (x) =>
              x.subjects &&
              x.subjects.some(y => y.id === subject.id &&
              y.module === subject.module &&
              x.id !== (data.id || pristine.id)
          ))
          .filter(
            (x) =>
              Math.floor(
                new Date().getTime() - new Date(x.createdAt).getTime()
              ) <= ago
          )
          .filter(
            (x) => ![TaskRequestStatus.Completed, TaskRequestStatus.Refused].includes(x.status) && !x.isDeleted
          );

        if (subject.module === OriginModuleEnum.Room && objectsByModule) {
          const objectsInRoom = Object.entries(objectsByModule)
            .flatMap((keyValuePair) => keyValuePair[1])
            .filter((x) => x.location && x.location.id === subject.id);

          related = related.concat(objectsInRoom.flatMap(getRelated));
        }

        return related;
      };

      const subjects = getValue("subjects") || [];

      if (subjects) {
        const related = subjects.map(x => getRelated(x)).flatMap(x => x);

        stateProxy.setter({ relatedTaskRequests: related }, resolve);
      } else {
        resolve();
      }
    });
  };

  renderRelatedRequests = (stateProxy) => {
    const value = stateProxy.getter("relatedTaskRequests");
    return (
      <CardList
        key="relatedTaskRequests"
        readOnly={true}
        value={value}
        title={arxs.t("wizard.task_request.related")}
        className="field"
      />
    );
  };
}
export default new TaskRequestWizard();
