import { ArXsState } from "infra/arxs";
import { OriginModuleEnum, RelationshipType, TaskStatus, ObjectDocumentType } from "infra/api/contracts";
import { ProductType } from "infra/Types";

import ModuleMetadata, { BoardViewType } from "modules/ModuleMetadata";

import InspectionWizard from "./InspectionWizard";
import InspectionActions from "./InspectionActions";
import InspectionBoard from "./InspectionBoard";
import TasklikeCard from "../tasks/TasklikeCard";

const getInspectionMetadata = (arxs: ArXsState): ModuleMetadata => {
  const module = OriginModuleEnum.PeriodicControl;

  return {
    module,
    title: arxs.t("modules.inspection"),
    icon: "far fa-tools",
    path: "/inspection",
    base: {
      name: "Inspection",
      route: "/inspection",
      getResource: () => arxs.Api.getResource("inspection"),
      subscribe: (id, module, handler) => {
        return arxs.Api.lookups.subscribeToId(module, id, handler);
      },
      readAction: "Control.Read",
      writeAction: "Control.Write",
    },
    settings: {
      name: "InspectionSettings",
      route: "/inspection/settings",
      getResource: () => arxs.ApiClient.facilitymanagement.inspectionSettings,
      readAction: "Control.Settings.Write",
      writeAction: "Control.Settings.Write",
      steps: [
        {
          title: arxs.t("field.processFlows"),
          fields: [
            [
              {
                name: "processFlows",
                type: "itemlist",
                children: [
                  { name: "legalStructure" },
                  { name: "branch", parent: "legalStructure" },
                  { name: "kind", code: "Control.ControlType" },
                  { name: "type", parent: "kind", code: "Control.Type" },
                  { name: "employee" },
                  { name: "userRole" },
                  {
                    name: "relationshipType",
                    values: [
                      RelationshipType.Responsible,
                      RelationshipType.CoResponsible,
                      RelationshipType.Cc,
                      RelationshipType.Assignee,
                    ],
                  },
                ],
              },
            ],
          ],
        },
      ],
    },
    detailspane: [
      {
        initiallyOpen: true,
        fields: [
          { name: "title", fullWidth: true },
          { name: "subjects" },
          { name: "description", fullWidth: true },
          { name: "taskType", fullWidth: true },
          { name: "priority", fullWidth: true },
          { name: "targetDate" },
          { name: "planning", fullWidth: true },
          {
            name: "estimatedDuration",
            unit: arxs.t("unit.hour").toLowerCase(),
          },
          {
            name: "actualDuration",
            unit: arxs.t("unit.hour").toLowerCase(),
          },
          { name: "estimatedCost", unit: arxs.t("unit.euro").toLowerCase() },
          { name: "actualCost", unit: arxs.t("unit.euro").toLowerCase() },
          { name: "kind" },
          { name: "type", parent: "kind" },
          { name: "origins" },
          { name: "relationships" },
        ],
      },
      {
        title: arxs.t("details_pane.location"),
        initiallyOpen: true,
        fields: [
          { name: "legalStructure" },
          { name: "branch", parent: "legalStructure" },
          { name: "building", parent: "branch" },
          { name: "location", parent: "building" },
          { name: "geoLocation" },
        ],
      },
      {
        title: arxs.t("details_pane.tags"),
        initiallyOpen: true,
        fields: [{ name: "tags" }],
      },
    ],
    allowedDocumentTypes: [
      ObjectDocumentType.Image,
      ObjectDocumentType.MainDocument,
      ObjectDocumentType.AdditionalDocument
    ],
    statuses: [
      TaskStatus.InProcess,
      TaskStatus.OnHold,
      TaskStatus.Active,
      TaskStatus.ToVerify,
      TaskStatus.ExecutionOverdue,
      TaskStatus.Completed,
      TaskStatus.MultiYearPlan,
    ],
    actions: InspectionActions.getActions(OriginModuleEnum.PeriodicControl),
    actionClass: InspectionActions,
    wizard: {
      wizardClass: InspectionWizard,
      steps: [
        {
          title: arxs.t("wizard.common.identification"),
          fields: [
            [
              {
                name: "title",
                title: arxs.t("wizard.field.title"),
              },
            ],
            [
              {
                name: "description",
                title: arxs.t("wizard.field.description"),
              },
            ],
            [
              {
                name: "subjects",
                type: "cardlist",
                props: {
                  modules: [
                    OriginModuleEnum.SchoolGroup,
                    OriginModuleEnum.School,
                    OriginModuleEnum.Building,
                    OriginModuleEnum.Room,
                    OriginModuleEnum.Labourmeans,
                    OriginModuleEnum.Pbm,
                    OriginModuleEnum.EquipmentInstallation,
                    OriginModuleEnum.HazardousSubstance,
                    OriginModuleEnum.CombinedInstallation,
                    OriginModuleEnum.IntangibleAsset,
                  ],
                  overridePrefilter: true,
                  disableInitialValue: true,
                },
                onChange: (
                  stateProxy,
                  fieldName: string,
                  row: any,
                  childName: string
                ) => {
                  InspectionWizard.applyProcessFlowForSubjects(stateProxy);
                },
                title: arxs.t("field.subjects"),
              },
              { name: "geoLocation" },
            ],
            [
              {
                name: "type",
                type: "codeelementlist",
                props: { code: "Control.ControlType" },
                parent: "kind",
                title: arxs.t("field.category"),
                onChange: (
                  stateProxy,
                  fieldName: string,
                  row: any,
                  childName: string
                ) => {
                  InspectionWizard.applyProcessFlowForSubjects(stateProxy);
                },
              },
              { name: "taskType" },
            ],
            [{ name: "tags", type: "tag" }],
            [
              {
                name: "origins",
                type: "cardlist",
                props: {
                  readOnly: true,
                },
              },
            ],
          ],
          getSecurityContext: (writeAction, getValue) => {
            const subjects = getValue("subjects") || [];
            return arxs.securityContext.buildForSubjects(
              writeAction,
              subjects
            );
          },
        },
        {
          title: arxs.t("wizard.common.details"),
          fields: [
            [
              {
                name: "priority",
                props: { required: true },
              },
              {
                name: "status",
                values: [
                  TaskStatus.InProcess,
                  TaskStatus.Active,
                  TaskStatus.OnHold,
                  TaskStatus.Completed,
                ],
                props: { required: true, disabledOnEdit: true },
              },
            ],
            [{ name: "mustBeVerified" }],
            [
              {
                name: "targetDate",
                isVisible: (stateProxy) => !stateProxy.getField("schedule"),
                mapToModel: (value) => {
                  if (value) {
                    value.setHours(23, 59, 59, 0);
                  }
                  return value;
                },
                props: { hideInTemplate: true },
              },
              {
                name: "notification",
                isVisible: (stateProxy) => !stateProxy.getField("schedule"),
              },
            ],
            [
              { name: "estimatedDuration" },
              { name: "actualDuration" },
            ],
            [{ name: "estimatedCost" }, { name: "actualCost" }],
            [{ name: "planFrom", type: "time" }],
          ],
        },
        {
          title: arxs.t("details_pane.involved_people"),
          fields: [
            [
              {
                name: "responsible",
                type: "cardlist",
                props: {
                  modules: [OriginModuleEnum.Employee],
                  condensed: true,
                  overridePrefilter: true,
                },
              },
            ],
            [
              {
                name: "relationships",
                type: "relationshiplist",
                props: {
                  types: [
                    RelationshipType.Assignee,
                    RelationshipType.CoResponsible,
                    RelationshipType.Cc,
                  ],
                },
              },
            ],
            [
              {
                name: "supplier",
                type: "cardlist",
                props: {
                  modules: [OriginModuleEnum.Supplier],
                  overridePrefilter: true,
                  singleSelection: true,
                  condensed: true,
                },
                title: arxs.t("field.supplier"),
              },
              {
                name: "contacts",
                type: "cardlist",
                props: {
                  modules: [OriginModuleEnum.Contact],
                  overridePrefilter: true,
                  setFilterOnField: "supplier",
                  condensed: true,
                },
                title: arxs.t("field.contact"),
              },
              {
                name: "witness",
                type: "cardlist",
                props: {
                  modules: [
                    OriginModuleEnum.Employee,
                    OriginModuleEnum.Contact,
                  ],
                  overridePrefilter: true,
                  condensed: true,
                },
                title: arxs.t("field.witness"),
                productType: ProductType.Hyperion,
              },
            ],
          ],
        },
        {
          title: arxs.t("wizard.common.form"),
          fields: [
            [
              {
                name: "formDefinition",
                props: { module: OriginModuleEnum.PeriodicControl },
              },
            ],
          ],
        },
        {
          title: arxs.t("details_pane.attachments"),
          fields: [
            [
              { name: "images", type: "image" },
              { name: "documents", type: "document" },
            ],
          ],
        },
      ],
      lookups: {
        taskPriorityRules: [],
        labourmeanMap: {},
        protectionEquipmentMap: {},
        hazardousSubstanceMap: {},
        equipmentMap: {},
        intangibleAssetMap: {},
        combinedInstallationMap: {},
        objectsByModule: {},
        legalStructureMap: {},
        branchMap: {},
        buildingMap: {},
        locationMap: {},
      },
      addNewState: "Active",
      navigatorAction: {
        title: arxs.t("wizard.task.plan"),
        route: "/planning",
      },
      fieldsWithIdsForDuplication: ["checklists"],
    },
    board: {
      lookups: {
        legalStructureMap: {},
        branchMap: {},
        buildingMap: {},
        locationMap: {},
        labourmeanMap: {},
        protectionEquipmentMap: {},
        equipmentMap: {},
        hazardousSubstanceMap: {},
        intangibleAssetMap: {},
        combinedInstallationMap: {},
        codeElementsById: {},
        inspections: [],
        inspectionMap: {},
        tagMap: {},
        employeeMap: {},
        userRoleMap: {},
        supplierMap: {},
        contactMap: {},
        periodicalMap: {},
        formMap: {},
      },
      gridColums: [
        { name: "status" },
        { name: "priority" },
        { name: "taskType" },
        { name: "activities" },
        { name: "uniqueNumber" },
        { name: "numberOfImages" },
        { name: "numberOfDocuments" },
        { name: "tags" },
        { name: "legalStructure" },
        { name: "branch" },
        { name: "building" },
        { name: "location" },
        { name: "geoLocation" },
        { name: "kind" },
        { name: "type" },
        { name: "title" },
        { name: "description" },
        { name: "estimatedDuration" },
        { name: "estimatedCost" },
        { name: "targetDate" },
        { name: "createdAt" },
        { name: "executedAt" },
        { name: "actualDuration" },
        { name: "actualCost" },
        { name: "responsible" },
        { name: "assignee" },
        { name: "owner" },
        { name: "coResponsibles" },
        { name: "numberOfFollowUps" },
        { name: "periodical_uniqueNumber" },
        { name: "subject_uniqueNumber" },
        { name: "subject_sort" },
        { name: "subject_kind" },
        { name: "subject_type" },
        { name: "subject_internalReference" },
        { name: "supplier" },
        { name: "contacts" },
      ],
      boardClass: InspectionBoard,
      bucketConfig: [
        {
          module: module,
          status: "InProcess",
          onDrop: (state) =>
            InspectionActions.dropActions().onDropInInProcess(state),
          requiredActions: ["accept", "set_inprocess"],
        },
        {
          module: module,
          status: "OnHold",
          onDrop: (state) =>
            InspectionActions.dropActions().onDropInOnHold(state),
          requiredActions: ["accept", "hold"],
        },
        {
          module: module,
          status: "Active",
          onDrop: (state) =>
            InspectionActions.dropActions().onDropInActive(state),
          requiredActions: ["accept", "activate"],
        },
        {
          module: module,
          status: "Completed",
          onDrop: (state) =>
            InspectionActions.dropActions().onDropInCompleted(state),
          requiredActions: ["accept", "complete"],
        },
        {
          module: module,
          status: TaskStatus.MultiYearPlan,
          onDrop: (state) => InspectionActions.dropActions().onDropInMultiYearPlan(state),
          requiredActions: ["transfer_to_myp"],
        },
      ],
      views: [
        BoardViewType.KanBan,
        BoardViewType.Grid,
        BoardViewType.Tree,
        BoardViewType.Map,
      ],
      reports: [{ name: "InspectionWorkOrder" }],
    },
    cardClass: TasklikeCard
  };
};

export default getInspectionMetadata;
